<template>
  <div>
    <div class="register-page">
      <div class="register-form p-2">
        <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
          <div class="text-center mb-2">
            <img class="transcrypt-logo-login-and-register mb-2 pointer"
                 src="/assets/remittance-assets/img/transcrypt-logo-colour.png" @click="goToHomepage()"/>
            <h2 class="mb-2">
              Transfer money with crypto
              seamlessly.
            </h2>
            <span class="color-secondary">Already signed up? <router-link
                to="/account/login" class="text-decoration-none">Login</router-link></span>
          </div>

          <div class="form-group">
            <div class="input-group">
              <input class="form-control" type="email" id="email-register" name="email"
                     placeholder="Your email address"
                     required=""
                     data-error="Please enter your name"
                     v-model="registeredEmail">
            </div>
            <p v-if="validationRegisteredEmailError" class="text-error"><small>Please enter a valid
              email</small></p>
          </div>

          <div class="form-group">
            <div class="input-group">
              <input class="form-control" type="password" id="password-register" name="password"
                     placeholder="Create a password"
                     required="" data-error="Please enter your message subject"
                     v-model="registeredPassword">
            </div>
            <p :class="{'text-error' : validationRegisteredPasswordError }"
               style="font-size: 0.700rem !important;">Password should be at least 6 characters,
              contain at least one numeric digit, one uppercase and one lowercase letter.</p>
          </div>


          <div class="form-group">
            <label for="country-of-residence">Country of Residence</label>
            <div class="input-group">
              <!--                            <select name="country" id="country-of-residence" class="form-control"-->
              <!--                                    v-if="countryList"-->
              <!--                                    v-model="registeredCountryOfResidence">-->
              <!--                                <option v-for="item in countryList" :key="item.name" :value="item.countryCode">-->
              <!--                                  {{item.name}} {{item.emoji}}-->
              <!--                                </option>-->
              <!--                            </select>-->
              <v-select :options="countryList"
                        :clearable="false"
                        id="country-of-residence"
                        label="name"
                        class="w-100"
                        v-model="registeredCountryOfResidence"
                        :reduce="item => item.countryCode">
                <template v-slot:option="item">
                  <country-flag :country="item.countryCode.toLowerCase()"></country-flag>
                  {{ item.name }}
                </template>
              </v-select>
            </div>

            <p v-if="validationRegisteredCountryOfResidenceError" class="text-error"><small>Please select a
              country</small></p>
          </div>

          <p class="text-center mt-4 text-error" v-if="registerErrorMessage">
            Error in signing up. Please try again.
          </p>
          <p class="text-center mt-4 text-error" v-else-if="registerEmailExistsError">
            Email already exists. Please use another email to sign up.
          </p>
          <p class="text-center mt-4 text-error" v-else-if="registerEmailValidationError">
            Please sign up with a valid email format.
          </p>

          <button @click="clickToSignUp()" class="btn btn-success btn-block mb-2" 
            :disabled="!termAndConditionStatus" 
            :class="{'btn-disabled': !termAndConditionStatus}"
            id="register-button"
          >
            <span v-if="!signingUpSpinner">Sign up</span><span v-else>Signing up...</span>
          </button>
          <hr class="mb-2">
          <div class="text-center small">
            <span>
              <input type="checkbox" class="m-0 p-0" style="outline: none" v-model="termAndConditionStatus">
            </span>
            By signing up, you agree to our
            <router-link to="/privacy-and-terms-policy" class="text-decoration-none" @click.native="termAndConditionHandler">Terms and Conditions</router-link>
            .
          </div>

          <!--                <div v-else>-->
          <!--                    <div class="text-center mb-4">-->
          <!--                        <img class="transcrypt-logo-login-and-register mb-5"-->
          <!--                             src="/assets/remittance-assets/img/transcrypt-logo-colour.png"/>-->
          <!--                    </div>-->
          <!--                    <br>-->
          <!--                    <br>-->
          <!--                    <br>-->
          <!--                    <div class="text-center mb-5 mt-5">-->
          <!--                        Thank you for registering with us. A verification email has been sent to <strong>{{registeredEmail}}</strong>.-->
          <!--                        Please check your inbox.-->
          <!--                    </div>-->
          <!--                    <br>-->
          <!--                    <br>-->
          <!--                    <br>-->
          <!--                    <button @click="goToLogin()" class="btn btn-success btn-block mt-5">-->
          <!--                        Continue Login-->
          <!--                    </button>-->
          <!--                </div>-->
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import {countries, getEmojiFlag, getUnicode} from 'countries-list';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import CountryFlag from 'vue-country-flag'

export default {
  name: 'Register',
  components: {
    CountryFlag,
    vSelect
  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      registeredEmail: "",
      registeredPassword: "",
      registeredCountryOfResidence: "",

      // registerSuccessful: false,

      registerErrorMessage: false,
      registerEmailExistsError: false,
      registerEmailValidationError: false,

      validationRegisteredEmailError: false,
      validationRegisteredPasswordError: false,
      validationRegisteredCountryOfResidenceError: false,

      signingUpSpinner: false,

      debugConsole: false,

      countryList: Object.entries(countries).map((item) => {
        return Object.assign(item[1], {countryCode: item[0]})
      }).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }).filter(item => ["AL", "BS", "BB", "BW", "KH", "IS", "JM", "MU", "MN", "MM", "NI", "PK", "PA", "SY", "UG", "YE", "ZW", "IR", "KP", "GS", "NG","RU","BY"].indexOf(item.countryCode) < 0),

     // T&Cs status
     termAndConditionStatus: false,
    }
  },
  watch: {
    $route() {

    },
  },
  computed : {
    ...mapGetters(["transferTypeGetter"])
  },
  mounted() {
    // window.addEventListener('keyup', this.pressEnterToRegister)

    var vm = this;

    if (this.debugConsole) console.log("Country list", this.countryList);

    let url = new URL(window.location);
    if (url.pathname === '/account/register') {
      document.addEventListener('keyup', this.pressEnterToRegister);

      //check the getters if what is the transferType
      console.log("TransferGetterType in account register remittance flow", this.transferTypeGetter);

      // this.submitTransferType('cash-out');
    }


  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.pressEnterToRegister)
  },
  metaInfo: {
    title: 'TransCrypt - Register Account | Send Money Faster with Cryptocurrency',
  },
  methods: {
    ...mapActions(
        [
          "registerUser", "getReceivers", "getSenderDetails",
          "getTransactions","submitTransferType"
        ]),
    pressEnterToRegister(e) {
      if (e.which === 13) {
        this.signingUpSpinner = true;
        setTimeout(() => {
          this.register()
        }, 1000)
      }
    },
    clickToSignUp() {
      this.signingUpSpinner = true;
      setTimeout(() => {
        this.register()
      }, 1000)
    },
    register() {
      //validation
      if (!this.registeredEmail) {
        this.validationRegisteredEmailError = true;
      } else {
        this.validationRegisteredEmailError = false;
      }

      if (!this.registeredPassword || !(this.registeredPassword && this.checkPassword(this.registeredPassword))) {
        this.validationRegisteredPasswordError = true;
        this.registerEmailValidationError = false;
        this.registerErrorMessage = false;
        this.registerEmailExistsError = false;
        if (this.debugConsole) console.log("password error");
      } else {
        this.validationRegisteredPasswordError = false;
        if (this.debugConsole) console.log("password true")
      }

      if (!this.registeredCountryOfResidence) {
        this.validationRegisteredCountryOfResidenceError = true;
      } else {
        this.validationRegisteredCountryOfResidenceError = false;
      }


      if (this.validationRegisteredEmailError || this.validationRegisteredCountryOfResidenceError || this.validationRegisteredPasswordError) {
        this.signingUpSpinner = false;
        return;
      }

      this.signingUpSpinner = true;

      //send data to backend
      let data = {};
      data.email = this.registeredEmail;
      data.password = this.registeredPassword;
      data.country = this.registeredCountryOfResidence;

      //send to backend for record purpose
      //check if query string ads available
      if (this.$store.getters.queryStringGetter) {
        data.source = this.$store.getters.queryStringGetter.source || this.$store.getters.queryStringGetter.utm_source;
      }

      if (this.debugConsole) console.log("Register data", data);

      if (data.email && data.password && data.country) {
        //post to API
        this.registerUser(data).then(result => {
              if (this.debugConsole) console.log('Result in Account Register form', result);
              if (result.data && result.data.jwt) {

                this.registerErrorMessage = false;
                this.registerEmailValidationError = false;
                this.registerEmailExistsError = false;
                this.validationRegisteredPasswordError = false;

                let data = {};
                data.email = result.data.email;
                data.token = result.data.jwt;
                data.country = result.data.country;
                data.userKey = result.data.user_key;
                this.$store.commit("REGISTER_USER", data);
                this.$store.commit("USER_LOGIN", data);

                this.getSenderDetails().then(result => {
                  if (this.debugConsole) console.log("Get Sender Details in Account Register Form", result);

                  this.getTransactions().then(result => {
                    if (this.debugConsole) console.log("Get transactions in Account Register Form", result.data);

                    this.getReceivers().then(result => {
                      if (this.debugConsole) console.log("Get Receivers in Account Register Form", result);

                    }).catch(error => {
                      if (this.debugConsole) console.log("Error in receivers account login form", error);

                    })
                  }).catch(err => {
                    if (this.debugConsole) console.log("Get transactions error Account Register Form", result.data);
                  })

                }).catch(error => {
                  if (this.debugConsole) console.log("Get User Details in comp error", error.response);
                })


                if (this.mode === 'production') {

                  if (this.$gtm.enabled()) {
                    console.log("Google Tag Manager enabled in account register form");
                    //google tag manager tracking
                    // this.$gtm.trackEvent({
                    //     event : 'successfulsignup',
                    //     TestVariable : 'My Test Variable'
                    // })

                    window.dataLayer?.push({
                      event: "successfulsignup"
                    })
                  }
                }

                //only go to next screen if sign up successful
                this.$router.push({path: '/transfer/remittance-form', query: {sign_up: 'successful'}});

                this.$store.commit("REMOVE_QUERY_STRING");


              } else {
                this.registerErrorMessage = true;
              }
            },
        )
            .catch(err => {
              if (this.debugConsole) console.log("Error in Account Register Form", err.response);

              if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.find(item => item.message === "email_exists")) {
                if (this.debugConsole) console.log('Email exists');

                this.registerEmailExistsError = true;
                this.registerErrorMessage = false;
                this.registerEmailValidationError = false;

              } else if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.find(item => item.message === "invalid" && item.path === "email")) {
                this.registerEmailValidationError = true;
                this.registerErrorMessage = false;
                this.registerEmailExistsError = false;
                this.validationRegisteredPasswordError = false;
              } else if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.find(item => item.message === "invalid" && item.path === "password")) {
                this.validationRegisteredPasswordError = true;
                this.registerEmailValidationError = false;
                this.registerErrorMessage = false;
                this.registerEmailExistsError = false;
              } else {
                this.registerErrorMessage = true;
                this.registerEmailExistsError = false;
                this.registerEmailValidationError = false;
              }

              this.signingUpSpinner = false;
            })
      }
    },
    goToLogin() {
      this.$router.push('/account/login');
    },
    goToHomepage() {
      this.$router.push('/');
    },
    termAndConditionHandler() {
      window.open(('/assets/pdf/Terms of Use Crypto Payment.pdf'), '_blank');
    }
  }
}
</script>
<style scoped>

</style>
